/* Checkout page styling */
.checkout {
  .social-login {
    &__divider {
      width: 100%;
      &:before {
        background: $color-light-gray;
        height: 1px;
      }
    }
    &__email-opt-in {
      margin-top: 20px;
    }
  }
}
/* Account signin page styling */
.sign-in-page {
  .divider {
    display: none;
  }
  .social-login {
    &__container {
      width: 88%;
      margin: 0 auto;
    }
    &__divider {
      width: 100%;
    }
    &__opt-in-label {
      margin-top: 20px;
      letter-spacing: 0.05em;
      font-size: 12px;
      margin-#{$ldirection}: 64px;
      text-align: $ldirection;
    }
    &__terms {
      padding: 0;
      text-align: $ldirection;
      p {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .email-optin {
      margin-top: 22px;
      text-align: $ldirection;
    }
  }
}

.device-mobile {
  .social-info {
    border-top: 1px solid $color-light-gray;
    &__header {
      font-size: 20px;
    }
  }
  .sign-in-component {
    .social-login {
      &__divider {
        width: 100%;
      }
      &__terms {
        p {
          line-height: 18px;
        }
      }
    }
  }
  .checkout__content {
    .email-optin {
      text-align: $ldirection;
    }
    .social-login {
      &__terms {
        padding: 0;
        text-align: $ldirection;
        p {
          font-size: 12px;
        }
      }
    }
  }
}
/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    @include swap_direction(margin, 0 20px);
    @media #{$medium-up} {
      @include swap_direction(margin, 0 100px);
    }
  }
  .social-login {
    &__container {
      margin: 0 auto;
      width: 97%;
      text-align: center;
    }
    &__divider {
      width: 100%;
    }
    &__terms {
      text-align: $ldirection;
      font-size: 12px;
    }
  }
  .tooltip {
    display: block;
  }
  .email-optin {
    margin-top: 20px;
    text-transform: none;
    letter-spacing: 0.05em;
    font-size: 12px;
    text-align: $ldirection;
  }
}
/* Order confirmation page */
#confirm {
  .pg_wrapper {
    .social-login {
      padding: 0;
      &__container {
        text-align: $ldirection;
        padding-bottom: 0;
        margin-bottom: 20px;
      }
      &__terms,
      &__divider {
        display: none;
      }
      &__title {
        display: block;
        font-size: 40px;
        font-family: $optimalight;
        text-transform: capitalize;
        letter-spacing: 0;
      }
      &__info {
        margin-bottom: 10px;
        display: block;
      }
      &__opt-in-label {
        text-transform: lowercase;
        font-size: 15px;
        letter-spacing: 0.05em;
      }
    }
  }
}
/* Profile preferences page */
.account-profile {
  &__connected-accounts {
    text-transform: uppercase;
    font-size: 12px;
  }
}

#registration {
  .social-info {
    &__content {
      p {
        text-transform: none;
      }
    }
  }
}

.device-pc {
  .social-info {
    padding-top: 0;
    border-top: 0;
    padding-#{$ldirection}: 19px;
    @media #{$large-up} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$xlarge-up} {
      margin-#{$ldirection}: 20%;
    }
    @media #{$medium-only} {
      margin-#{$ldirection}: 25%;
    }
    .sidebar-page {
      &__content {
        padding: 0;
      }
    }
    &__header {
      font-size: 30px;
    }
  }
  @media #{$medium-only} {
    .sidebar-page {
      &__content {
        padding: 0;
      }
    }
  }
}

.page-wrapper {
  .social-login {
    .tooltip {
      display: block;
    }
  }
}
/* Tooltip */
.social-login {
  &__container {
    .tooltip {
      text-transform: none;
      letter-spacing: 0.05em;
      font-size: 15px;
      text-align: $ldirection;
      position: relative;
      display: inline-block;
      width: 100%;
      .tooltiptext-over {
        visibility: hidden;
        width: 100%;
        background-color: $color-dark-gray;
        color: $color-white;
        text-align: justify;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 99;
        left: 0%;
        opacity: 0;
        transition: opacity 0.3s;
        font-weight: normal;
        bottom: 100%;
      }
      a {
        color: $color-white;
        text-decoration: underline;
      }
      .tooltiptext-over::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 10%;
        border-width: 5px;
        border-style: solid;
        border-color: $color-dark-gray transparent transparent transparent;
      }
      &:hover .tooltiptext-over {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

#signin {
  .password-field__info {
    @media #{$medium-up} {
      width: 42%;
    }
  }
}
