@import 'base/locale_fonts_include';

$spacing: -0.05em;

.free_standard_shipping_returns {
  font-family: $akzidenz;
}

.product-full__misc-flag {
  font-family: $akzidenz;
}

.giftcard-page {
  .giftcard-label,
  .giftcard-copy,
  .giftcard-copy p {
    font-family: $akzidenz;
  }
}

.loyalty-account-page #loyalty__page__history {
  .loyalty__panel__points__instruction__collect_points {
    font-family: $akzidenz;
  }
}

.formatter-estee-edit__content {
  .basic-textarea-v1 {
    p {
      font: 18px/26px $akzidenz;
    }
  }
}

.ee-article-image__caption {
  font: 15px/24px $akzidenz;
}

.spp-product__misc-flag {
  font-family: $akzidenz;
}

.extended-mpp.sample-mini-bar .mpp__product {
  .product_brief__headers {
    .product_brief__shadename.sized {
      font-family: $akzidenz;
    }
  }
}

#colorbox.colorbox__quickshop {
  .quickshop__misc-flag {
    font-family: $akzidenz;
  }
}

.spp-product__details {
  .spp-product__details-attribute__label {
    font-family: $akzidenz;
  }
}

.product_brief__misc-flag {
  font-family: $akzidenz;
}

.product_brief__buttons--shaded {
  .button-color-picker {
    div.tooltip p {
      font-family: $akzidenz;
    }
  }
}

#mpp__filter-tab {
  font-family: $akzidenz;
}

#mpp__filter {
  font-family: $akzidenz;
  .mpp__filter-column {
    font-family: $akzidenz;
  }
}

#colorbox.magic_spoky_popover {
  #cboxContent {
    #cboxClose {
      font-family: $akzidenz;
    }
  }
}

.video_local-stacked {
  .free-shipping {
    font-family: $akzidenz;
  }
}

.video_youtube-stacked {
  .free-shipping {
    font-family: $akzidenz;
  }
}

.loyalty-tout {
  &__no {
    font-family: $akzidenz;
  }
}

.responsive-carousel {
  .slide__link {
    font-family: $akzidenz;
  }
}

.responsive-carousel__title {
  font-family: $akzidenz;
}

.video_youtube_double {
  .video_youtube_double__content {
    .video-title {
      span {
        font-family: $akzidenz;
      }
    }
  }
}

.mobile-search-heading {
  font-family: $akzidenz;
}

.search-product__misc-flag {
  font-family: $akzidenz;
}

@media (min-width: 768px) {
  .boutique-custom__link {
    a.button-explore.button-explore-label {
      font-family: $akzidenz;
    }
  }
}

.footer-phone {
  @media #{$small-only} {
    .footer-phone__link {
      font-family: $akzidenz;
    }
  }
}

.footer-forms-location-sms-email-signup-elc-nodeblock {
  @media #{$small-only} {
    .footer-forms__header {
      font-family: $akzidenz;
    }
  }
}

.page-footer .language-select {
  .language-select__header {
    font-family: $akzidenz;
  }
}

.customer-service {
  .cs-quick-info__title {
    font-family: $akzidenz;
  }
  .sidebar-page__content {
    ul {
      font-family: $akzidenz;
    }
  }
}
/* Letter Spacing Implemented for OptimaNovaLTProLight */
@mixin headline--secondary {
  letter-spacing: $spacing;
}
%headline--secondary,
%headline--medium {
  @include headline--secondary;
}

.headline--secondary,
.headline--medium {
  @extend %headline--secondary;
}

@mixin headline--secondary--plain {
  letter-spacing: $spacing;
}
%headline--secondary--plain {
  @include headline--secondary--plain;
}

@mixin headline--tertiary {
  @media #{$medium-up} {
    letter-spacing: $spacing;
  }
}
%headline--tertiary {
  @include headline--tertiary;
}

.headline--tertiary {
  @extend %headline--tertiary;
}

@mixin headline--tertiary--plain {
  @media #{$medium-up} {
    letter-spacing: $spacing;
  }
}
%headline--tertiary--plain {
  @include headline--tertiary--plain;
}

.headline--page {
  @media #{$medium-up} {
    letter-spacing: $spacing;
  }
}

@mixin headline--products {
  letter-spacing: $spacing;
}
%headline--products {
  @include headline--products;
}

.headline--products {
  @extend %headline--products;
}

@mixin headline--products--plain {
  letter-spacing: $spacing;
}
%headline--products--plain {
  @include headline--products--plain;
}

.ee-landing__filters__side__wrapper {
  letter-spacing: $spacing;
}

@media #{$medium-up} {
  .ee-landing__filters li {
    letter-spacing: $spacing;
  }
}

.spp-discover-more-section-mobile {
  .discover_more__product {
    .discover_more__product-header {
      letter-spacing: $spacing;
    }
    .discover_more__product-sub-header {
      letter-spacing: $spacing;
    }
  }
}

.order_arrival_details {
  .title {
    letter-spacing: $spacing;
  }
}

.spp-howtouse-steps-mobile-formatter {
  #spp-howtouse-headline {
    letter-spacing: $spacing;
  }
  .spp-howtouse-steps-mobile {
    .step-headline {
      letter-spacing: $spacing;
    }
  }
}

.wishlist-confirm__message {
  letter-spacing: $spacing;
}

.spp-product__how-to-use__steps {
  .spp__steps {
    .spp_howtouse_content {
      .step-headline {
        letter-spacing: $spacing;
      }
    }
  }
}

.mpp__category-links--content {
  letter-spacing: $spacing;
}

.loyalty_market {
  .loyalty_market__header {
    letter-spacing: $spacing;
  }
  .loyalty_market__tiers__header {
    letter-spacing: $spacing;
  }
  .loyalty_market__tier__header {
    letter-spacing: $spacing;
  }
  .loyalty_market__points__header {
    letter-spacing: $spacing;
  }
}

.header__headline {
  &.headline--small,
  &.headline--small2,
  &.headline--small3 {
    @media #{$small-only} {
      letter-spacing: $spacing;
    }
  }
}

.device-mobile {
  .header__headline {
    &.headline--small,
    &.headline--small2,
    &.headline--small3 {
      @media #{$small-only} {
        letter-spacing: $spacing;
      }
    }
  }
}

.r3__overlay {
  .r3__overlay-header {
    letter-spacing: $spacing;
  }
}

.signup-tout-wrapper {
  .signup-tout-content {
    .headline1,
    .headline2 {
      letter-spacing: $spacing;
    }
  }
}

.device-mobile {
  .signup-tout-wrapper {
    .signup-tout-content {
      .headline1,
      .headline2 {
        letter-spacing: $spacing;
      }
    }
  }
  .signup-tout-flxible-mobile-wrapper {
    .signup-tout-content {
      .headline1,
      .headline2 {
        letter-spacing: $spacing;
      }
    }
  }
}

.signup-tout-flexible-wrapper {
  .signup-tout-content {
    .headline1,
    .headline2 {
      letter-spacing: $spacing;
    }
  }
}

.special-offer-row-promo-wrapper {
  &.special-offer-theme-regular {
    .special-offer-headline {
      letter-spacing: $spacing;
    }
  }
}

.special-offer-row-wrapper {
  &.special-offer-theme-regular {
    .special-offer-headline {
      letter-spacing: $spacing;
    }
  }
}

.special-offer-row-mobile {
  &.special-offer-theme-regular {
    .special-offer-headline {
      letter-spacing: $spacing;
    }
  }
}

.video_youtube,
.video_youtube_double {
  &.fonts-theme-regular {
    header {
      .video-headline-mobile {
        letter-spacing: $spacing;
      }
    }
  }
}

.video_youtube_custom_mobile {
  &.fonts-theme-regular {
    header {
      .video-headline-mobile {
        letter-spacing: $spacing;
      }
    }
  }
}

.video_youtube_custom_mobile.v2 {
  .video_youtube__content {
    .video-title {
      span {
        letter-spacing: $spacing;
      }
    }
  }
}

.ff-quiz__headline--secondary {
  letter-spacing: $spacing;
}

.ff-results {
  .ff-results__count {
    letter-spacing: $spacing;
  }
  .ff-results__regimen-step-gutter {
    letter-spacing: $spacing;
  }
}

.ff-quiz__inner {
  .ff-quiz__edit-headline {
    letter-spacing: $spacing;
  }
  .ff-quiz__headline {
    letter-spacing: $spacing;
  }
  .ff-quiz__headline--secondary {
    letter-spacing: $spacing;
  }
}

.el-search-block {
  input[type='text'].form-text {
    letter-spacing: $spacing;
  }
}

.el-search-block__links {
  a {
    letter-spacing: $spacing;
  }
}

.search-page__headline {
  letter-spacing: $spacing;
}

input[type='text'].search-term {
  letter-spacing: $spacing;
}

.loyalty_popover__promo-header {
  letter-spacing: $spacing;
}

.device-mobile {
  #colorbox.welcome15-overlay.with-second-language {
    .email_popover__content {
      & > h3,
      & > h4 {
        letter-spacing: $spacing;
      }
    }
  }
}

.up-next__label {
  letter-spacing: $spacing;
}
/* English font applied as per EL NOAM */
.advanced-night-repair-page,
.advanced-time-zone-page,
.clear-difference-page,
.daywear-page,
.enlighten-page,
.idealist-page,
.new-dimension-page,
.perfectionist-page,
.resilience-lift-page,
.revitalizing-supreme-page,
.mpp-cleansers-toners-elc-mpp,
.mascara-elc-mpp,
.landing-page-bronze-goddess-page,
.double-wear-page,
.little-black-eye-stylists-page,
.perfectionist-makeup-page,
.pure-color-page,
.pure-color-envy-collection-page,
.sumptuous-page,
.modern-muse-le-rouge-landing-page,
.page-products-rn-listesi-parfm,
.page-products-cat8258,
.page-products-rn-listesi-cilt-bakm-kategoriye-gre-bb-creme {
  .mpp__header {
    font-family: $optimalight;
  }
}

.page-product-rn-listesi-parfm-este-lauder-wood-mystique-eau-de-parfum-spray,
.page-product-rn-listesi-cilt-bakm,
.page-product-rn-listesi-makyaj,
.page-product-rn-listesi-parfm,
.page-product-rn-listesi-en-yeniler-hediyeler {
  h3.product-full__title {
    font-family: $optimalight;
  }
  h4.product-full__subtitle {
    font-family: $optimalight;
  }
}

.brand-aerin {
  .page-main {
    .headline--products {
      font-family: 'FuturaPT-Light';
    }
    .mpp__header {
      font-family: 'FuturaPT-Light';
    }
    .product_brief__header,
    .product_brief__sub-header {
      font-family: 'FuturaPT-Light';
    }
    .product_brief__price,
    h5.product_brief__misc-flag {
      font-family: 'FuturaPT-Demi' !important;
    }
    ul.product__inventory-status {
      li {
        font-family: 'FuturaPT-Light';
      }
    }
    .mpp__product-sort-container {
      a.selectBox.mpp__product-sort {
        font-family: 'FuturaPT-Light' !important;
      }
    }
    .product-brief__view-details {
      font-family: 'FuturaPT-Medium';
    }
    h3.product-full__title,
    .product-full__attribute p,
    h5.free_standard_shipping_returns {
      font-family: 'FuturaPT-Light' !important;
    }
    .product-full__description-section {
      .BVRRPrimarySummary {
        font-family: 'FuturaPT-Medium';
      }
      a.product-full__detail-link {
        font-family: 'FuturaPT-Medium';
      }
      .product-full__price {
        font-family: 'FuturaPT-Medium';
      }
    }
    .spp-product__details {
      h4.spp-product__details-header {
        font-family: 'FuturaPT-Light' !important;
      }
      .spp-product__details-description {
        font-family: 'FuturaPT-Light' !important;
        p {
          font-family: 'FuturaPT-Light' !important;
        }
      }
    }
    .product-full__add-button,
    .product-full__wishlist {
      font-family: 'FuturaPT-Medium';
    }
    .world-of-aerin-page {
      div,
      h4 {
        font-family: 'FuturaPT-Light' !important;
      }
    }
    .aerin-about-aerin-page,
    .world-of-aerin-the-aerin-fragrance-collection-page {
      div,
      h1,
      h2,
      p {
        font-family: 'FuturaPT-Light' !important;
      }
    }
  }
}
