/*
EMEA specific overrides (aplies to all locales)
*/
@import '../../estee_base/scss/theme-bootstrap';

@import 'overrides/overrides';
@import 'sections/checkout/checkout';
@import 'sections/checkout/checkout_samples';
@import 'drupal_modules/esteelauder_core/colorbox';
@import 'components/header/page_navigation';
@import 'drupal_modules/esteelauder_product/pc_product_full';
@import 'drupal_modules/esteelauder_product/pc_spp';
@import 'sections/account/address_form';
@import 'sections/account/beauty_profile';
@import 'sections/account/account';
@import 'sections/bt/bt';
@import 'sections/products/product_hero_tout';
@import 'sections/customer_service/customer_service';
@import 'components/clickcollect';
@import "sections/checkout/enhanced-delivery";
@import 'sections/checkout/opc_emea_update/_opc_emea_update__checkout-shared';
@import 'sections/checkout/opc_emea_update/_opc_emea_update__checkout-review';
@import 'sections/checkout/opc_emea_update/_co_emea_update__checkout-viewcart';
@import 'components/social_login.scss';
@import 'components/olapic';

@if $co_updates == true {
  @include co_updates_shared;
  @include co_updates_review;
  @include co_updates_viewcart;
}

@import 'sections/_power_review';
@import 'sections/pc/_all';

$elist-banner-desktop: '/media/loyalty/elist-new-banner-pc.jpg' !default;
$elist-banner-mobile: '/media/loyalty/elist-new-banner-pc.jpg' !default;
$elist-banner-account-login: '/media/loyalty/account-signin-banner.jpg' !default;
/* stylelint-disable max-line-length */
$elist-banner-account-index-non-member: '/media/loyalty/account-page-non-pc-banner.jpg' !default;
$elist-banner-account-login-desktop-padding-top: 150px !default;
$elist-banner-account-login-mobile-padding-top: 20vw !default;
