.spp-product__anchor-nav li {
  padding: 0 5px;
}

.spp-product__mini-bag-quantity {
  width: 115px;
}

.spp-product__mini-bag-add-button {
  width: 111px;
}

.product-full {
  &__image-container {
    padding-top: 100px;
  }
}
