// Abstract specific font names away
$optimalight: 'OptimaNovaLTPro-Light';
$akzidenz: 'AkzidenzGrotesk W1G', Arial, Helvetica;

@font-face {
  font-family: '#{$optimalight}';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaNovaLTPro-Light.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}


$optimanova: 'OptimaNovaLTPro-Light';
