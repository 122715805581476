.sd-product-spp {
  // Hide QTY Picker for Specific SKU
  &[data-sku-base-id='159639'] {
    .elc-quantity-selector-button {
      display: none;
    }
    .elc-product-full-cta-wrapper {
      padding: 0;
    }
  }
}
