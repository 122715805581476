.page-sticky-footer {
  #node-198670 {
    border-right: 1px solid $color-comet;
  }
}

.estée-e-list-loyalty-program-esteelaudercom-page {
  .elist-content {
    .beauty-header {
      @media #{$small-only} {
        font-size: 40px;
      }
    }
  }
}

.checkout__panel-content {
  .slick-dots {
    padding-bottom: 10px;
  }
}

#confirmation-panel {
  .confirmation-page__points__earned,
  .loyalty-find-out-more {
    @media #{$small-only} {
      padding-left: 25px;
    }
  }
}

#colorbox.signin-overlay-wrapper {
  $popup-height: 75vh;
  $popup-width: 1022px;
  // all !importat rules here are used to overwrite inline styling added by js, or !important rules set in esteelauder/themes/emea_base/scss/loyalty/_loyalty_signin.scss
  height: $popup-height !important;
  width: $popup-width !important;
  left: 50% !important;
  top: 50% !important;
  @media #{$medium-up} {
    position: fixed !important;
    transform: translate3d(-50%, -50%, 0);
  }
  @media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    // ipad portrait only
    transform: translate3d(-50%, -50%, 0) scale(0.75);
  }
  margin: 0;
  #cboxWrapper {
    height: $popup-height !important;
    width: $popup-width !important;
    #cboxContent {
      height: $popup-height !important;
      width: $popup-width !important;
      padding-top: 30px;
      @media #{$medium-up} {
        padding-top: 50px;
      }
      #cboxLoadedContent {
        height: $popup-height !important;
        width: $popup-width !important;
      }
      .sign-in-component {
        padding: 0 30px;
        @media #{$medium-up} {
          width: 800px;
          padding: 0;
        }
        &__form {
          .signin-overlay__toggle-form {
            display: block;
            text-align: left;
          }
        }
      }
      .sign-in-component__form {
        height: auto;
      }
      .sign-in-component__loyalty-errors {
        max-width: 315px;
      }
      .password-field__info {
        clear: both;
      }
    }
  }
}

.loyalty-errors li {
  color: $color-red;
}

#cboxWrapper {
  .loyalty_popover {
    .form-text {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .text--checkbox-label {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .password-field__rules {
      li {
        color: $color-navy;
      }
    }
    .js-input_phone {
      width: 100%;
    }
  }
  .container--submit {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ul {
    color: $color-white;
  }
}

.sign-in-page {
  .registration-confirmation {
    @media #{$medium-up} {
      width: 50%;
    }
  }
  .sign-in-component {
    padding-top: 10px;
    .sign-in-component__form--registration {
      @media #{$medium-up} {
        .password-field__info {
          right: 0% !important;
          top: 37px !important;
        }
        .password-field__info::before {
          display: none;
        }
      }
      .sign-in-component__password-wrapper {
        .sign-in-component__show-password-wrapper {
          margin: 10px 0px 5px 0px;
        }
      }
      .sign-in-component__registration-options {
        @media #{$medium-up} {
          width: 600px;
        }
      }
    }
  }
}

#content {
  .loyalty_mrkt_ldng,
  .loyalty_market__cta {
    .text--checkbox-label {
      width: auto;
      text-align: left;
      font-size: 15px;
      padding-top: 1px;
    }
    .js-loyalty-gdpr-signup {
      margin: auto;
      @media #{$medium-up} {
        width: 700px;
      }
    }
  }
}

#loyalty__content {
  #loyalty__panel__benefits {
    .loyalty_points {
      display: block;
      text-align: center;
    }
  }
  .e-list_not_signed_wrapper {
    position: relative;
    @media #{$medium-up} {
      background: url('/media/loyalty/account-page-non-pc-banner.jpg');
      height: auto;
      background-size: cover;
    }
    @media #{$xxlarge-up} {
      height: 470px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1279px) {
      // ipad fixes
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        opacity: 0.85;
      }
    }
    .e-list_not_signed {
      &__text {
        @media #{$xxlarge-up} {
          width: 55%;
          margin-top: 5px;
        }
      }
      &__link-container {
        @media #{$xxlarge-up} {
          margin-top: 0;
        }
      }
      .form-submit {
        @media #{$xxlarge-up} {
          display: block;
        }
      }
      &-left {
        @media #{$medium-up} {
          position: relative;
          #{$rdirection}: 0;
        }
        @media #{$xxlarge-up} {
          width: 90%;
          .sub_title {
            padding-top: 10px;
          }
          .error {
            margin-top: 0;
          }
          .e-list_not_signed {
            &__link-container {
              margin: 5px 0;
            }
          }
        }
      }
      &-right {
        @media #{$xxlarge-up} {
          width: 72%;
          &__text {
            margin: 5px 0;
          }
        }
        &__button-container {
          padding: 0 20px;
          iframe {
            display: none;
          }
          @media #{$xxlarge-up} {
            padding-#{$ldirection}: 0;
            .error-container {
              padding: 0;
              width: 67%;
              .error {
                margin: 0 0 5px;
              }
            }
            label {
              width: 60%;
              margin: 15px 0;
              &.error {
                margin: 10px 0;
              }
            }
            .btn_join {
              margin-#{$ldirection}: 0;
            }
          }
        }
      }
    }
  }
  .e-list_not_signed {
    .legal_frame {
      width: 100%;
      @media #{$medium-up} {
        max-height: 150px;
      }
    }
    .error-container {
      font-size: 12px;
      padding: 15px 0;
    }
    .error-container:empty {
      display: none;
    }
    .e-list_not_signed-left__inner {
      .title_main {
        @media #{$medium-up} {
          font-size: 29px;
          line-height: 1;
        }
      }
    }
    #loyalty_points__commercial_mobile_number,
    .text--checkbox-label,
    .form-submit {
      @media #{$medium-up} {
        margin-bottom: 15px;
      }
      @media #{$xxlarge-up} {
        margin-bottom: 0;
      }
    }
    #loyalty_points__commercial_mobile_number {
      @media #{$xxlarge-up} {
        margin-top: 0;
      }
    }
  }
  .e-list_not_signed-right {
    position: relative;
  }
  #loyalty__panel__offers__sku {
    .loyalty__panel__offers__sku {
      &__list {
        padding-top: 30px;
      }
      &__product {
        height: 520px;
      }
    }
  }
  .e-list_signed {
    .chart {
      display: block;
    }
  }
}

.node-198678 {
  .legal_frame {
    width: 100%;
  }
}

body.device-mobile {
  #loyalty__content {
    .e-list_not_signed_wrapper {
      background-image: none;
    }
  }
  #colorbox.loyalty-offer-overlay {
    position: absolute !important;
  }
}

.loyalty_mrkt_ldng .loyalty_join_signin .js-loyalty-gdpr-signup .text--checkbox-label,
.js-loyalty-gdpr-signup .text--checkbox-label {
  width: auto; // ie11 fix, width initial is not supported by ie 11
}

#colorbox.loyalty-offer-overlay {
  height: 710px !important;
  #cboxWrapper {
    height: 710px !important;
    #cboxMiddleLeft {
      height: 710px !important;
    }
    #cboxContent {
      height: 710px !important;
      .loyalty_popover {
        .loyalty_popover__welcome {
          .loyalty_popover__firstname {
            @media #{$small-only} {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#signin {
  .sign-in-page.account {
    .sign-in-page--loyalty-container-banner {
      display: none;
    }
  }
  .sign-in-component__form--registration {
    label {
      @media #{$medium-up} {
        max-width: 530px;
      }
    }
  }
}

#loyalty__panel__offers {
  .loyalty__panel__offers__button {
    @media #{$small-only} {
      height: 41px;
      line-height: 20px;
    }
  }
}

#shipping.device-mobile {
  .loyalty-join-cart {
    display: none;
  }
}

.loyalty_market.is_loyalty_member {
  .loyalty_market__cta {
    display: block;
  }
  .loyalty_join_signin {
    display: none;
  }
}

#main {
  .loyalty_market__tiers__header {
    @media #{$small-only} {
      font-size: 25px;
      line-height: 1.2;
    }
  }
}

.customer-service {
  .sidebar-page__content {
    .loyalty_faq {
      li {
        @media #{$medium-up} {
          min-height: unset;
        }
      }
    }
  }
}

.sign-in-component .sign-in-component__confirm--registration .sign-in-component__close {
  @media #{$medium-up} {
    @include swap_direction(padding, 8px 0 0 0);
  }
}

#loyalty__page__tout {
  #loyalty__panel__points {
    .loyalty__panel__points__title--loyalty {
      @media #{$medium-up} {
        margin-top: 0px;
      }
    }
  }
}
#commercial_consent_phone_number {
  width: 225px;
}

.loyalty_mrkt_ldng {
  .elist-banner {
    .elist {
      &-banner-content {
        @media #{$medium-up} {
          width: 42%;
        }
        @media #{$xxlarge-up} {
          width: 44%;
        }
      }
    }
  }
}

.loyalty-account-page {
  .loyalty__panel__about__landing {
    .loyalty_mrkt_ldng {
      .elist-banner {
        .elist {
          &-banner-content {
            @media #{$medium-up} {
              width: 46%;
            }
            @media #{$xxlarge-up} {
              width: 44%;
            }
          }
        }
      }
    }
  }
}
