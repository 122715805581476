@if $password_strengthen {
  .password-field {
    &__info {
      #signin &,
      #confirm &,
      #password_reset & {
        @media #{$medium-up} {
          width: 230px;
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: 0;
          transform: translateY(-14px);
        }
      }
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__fieldset {
        .form-item {
          input[type='password'] {
            width: 100%;
          }
        }
      }
      &__rules {
        font-size: 9px;
        @media #{$medium-up} {
          font-size: 12px;
        }
        li {
          display: flex;
        }
      }
      &__action {
        .form-cancel {
          border: 1px solid $color-light-gray;
        }
        .form-submit {
          border: 1px solid $color-light-gray;
        }
      }
    }
  }
}
