.compare-checkbox-wrapper {
  .compare-checkbox-label {
    &:before,
    &:after {
      left: 55px;
    }
  }
}

.product__price-per-unit {
  font-size: 13px;
}

.product-full__price-text {
  float: none;
  font-size: 15px;
}

.spp-product__legal {
  margin-top: 5px;
}

select.product-full__quantity,
a.selectBox.product-full__quantity {
  width: 115px;
}

.mpp__product {
  .product_brief__buttons {
    .button--secondary {
      &.product_brief__button--shop-now {
        line-height: normal;
        padding-top: 2px;
      }
    }
  }
}
