#bt_settings div.bt-content ul.slide-toggle {
  background: transparent url('/media/images/cookies/all-off_de.png') 50% 100% no-repeat;
}

#bt_settings div.bt-content div.mask ul.slide-toggle {
  background-image: url('/media/images/cookies/all-on_de.png');
}

#bt_settings div.bt-content-mobile ul.slide-toggle {
  background-image: url('/media/images/cookies/m_all-off_de.png');
}

#bt_settings div.bt-content-mobile div.mask ul.slide-toggle {
  background-image: url('/media/images/cookies/m_all-on_de.png');
}
