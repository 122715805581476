.legal_frame {
  .legal_iframe {
    width: 100%;
    height: 10rem;
    border: 1px solid $color-dark-gray;
    margin: 10px 0;
    padding: 5px;
    font-size: 90%;
  }
  width: 100%;
  height: 10rem;
  border: 1px solid $color-light-gray;
  margin: 10px 0;
  font-size: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-word;
}

.sign-in-component {
  &__mobile {
    .form-text {
      width: 100%;
    }
    .field {
      &.error {
        width: 100%;
      }
    }
  }
  &__legal {
    font-size: 10px;
  }
  &__adv-legal {
    @media (min-width: $medium-min) {
      width: 60%;
    }
  }
  &.registration {
    .legal_frame {
      @media (max-width: $medium-min) {
        margin: auto 20px;
      }
      .legal_iframe {
        height: 7rem;
        margin: 5px 0;
      }
    }
    .form-submit {
      margin-bottom: 5px;
    }
  }
  .sign-in-component {
    &__form--registration {
      .sign-in-component {
        &__password-wrapper {
          margin-bottom: 5px;
        }
        &__registration-options {
          margin: 0 0 3px;
        }
      }
    }
    &__header {
      margin: 0 0 3px;
    }
    &__form {
      .form-submit {
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.subheadline {
  &--section {
    padding-bottom: 5px;
    a {
      text-decoration: underline;
    }
  }
}

.registration-page {
  .email-promotions {
    margin-bottom: 10px;
    padding-bottom: 0;
  }
  .sms-promotions {
    border-top: none;
  }
}

.section-data-privacy-statement {
  .page-header,
  .page-footer {
    display: none !important;
  }
  .page-wrapper {
    padding-top: 0;
  }
  .LPMcontainer,
  .optanon-alert-box-wrapper {
    display: none !important;
  }
}

.social-login {
  &__terms {
    display: block;
  }
  &__container {
    .tooltip {
      width: 80%;
    }
  }
}

.checkout {
  &__content {
    .terms {
      margin: 10px 0 15px;
    }
  }
}

#confirm {
  .pg_wrapper {
    .social-login {
      &__terms {
        display: block;
      }
      &__container {
        padding: 0 5px;
      }
    }
  }
}

.error {
  a {
    color: $color-red;
  }
}

.error_messages {
  .s {
    margin-bottom: 10px;
  }
}

.legal-presales-info-body {
  .checkout {
    margin: 0 auto;
    padding: 20px 30px;
  }
  h4 {
    font-family: $optimaregular;
    margin-bottom: 30px;
  }
  p {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  table {
    margin-bottom: 30px;
    p {
      margin: 0;
    }
  }
}
