.elc-product-full {
    .elc-product {
        &-promo-message {
            @media #{$medium-up} {
                margin: 10px 0 10px;
            }
        }
    }
}

.sd-product-grid {
  .elc-grid-item-product {
    .elc-product-quick-view-button {
      padding: 0 10px;
    }
  }
}
