/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful {
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: 'yardımcı oldu  (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: 'yardımcı olmadı  (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
            &-btn {
              padding: 12px 6px;
            }
          }
          .pr-rd-helpful-action {
            margin-right: 0;
          }
          .pr-rd-flag-review-container {
            margin-left: 0;
            a {
              font-size: 9px;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='yaşınız'] {
          &::before {
            content: 'FİLTRELER:';
            font-size: 13px;
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
        .pr-rd-review-header-contents {
          width: 58%;
        }
        .pr-multiselect[data-reactid-powerreviews$='yorsunuz'] {
          display: none;
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            width: 60px;
            .pr-search-icon {
              &::after {
                content: 'ARA';
              }
            }
          }
          .pr-rd-search-reviews-input {
            padding-right: 70px;
          }
        }
        .pr-rd-main-header-search {
          width: 35%;
        }
        .pr-rd-review-header-sorts {
          padding: 15px 0 0 12px;
          .pr-rd-sort-group {
            &::before {
              content: 'göre sırala: ';
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                padding: 10px 5px;
              }
            }
          }
          .pr-snippet-rating-decimal {
            &:after {
              content: ' / 5';
            }
          }
        }
      }
    }
  }
}
