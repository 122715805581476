#rn_container {
  #renutriv_section-4 {
    #renutriv_section-4_li1 {
      .content {
        .experience_now {
          img {
            position: relative;
          }
        }
      }
    }
  }
}

.brick--c .homepage__hero--c {
  width: auto;
  max-width: initial;
}

.brick--b .homepage__hero--b {
  width: auto;
  max-width: initial;
}

.boutique-custom__link {
  a.button-explore.button-explore-label {
    width: auto;
  }
  a.button-explore.button-explore-label:before {
    height: 52px;
  }
}

#cboxClose {
  right: 5px !important;
  top: 5px !important;
}

.section-store-locator {
  .store-locator__sub-pages {
    .gmnoprint {
      div[title^='Sola yatay kaydir'],
      div[title^='Saga yatay kaydir'],
      div[title^='Yukariya yatay kaydir'],
      div[title^='Asagiya yatay kaydir'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.ie-8 {
  .page-utilities {
    &__cart-count {
      min-width: 20px;
    }
  }
}

.ff-quiz__inner {
  .ff-quiz__link--help {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .page-main {
    .mpp__product-sort-container {
      .mpp__product-sort {
        min-width: 160px;
      }
    }
  }
  .new-dimension-page {
    .header__headline.headline--small {
      margin-top: 15%;
    }
  }
}

@media (min-width: 737px) and (max-width: 1024px) and (orientation: portrait) {
  .hero-block-wrapper {
    #a-newdimensionancillaries-36 {
      h1 {
        font-size: 2em;
      }
    }
  }
  .new-dimension-page {
    .header__headline.headline--small {
      margin-top: 75px;
    }
  }
  #supreme-simple {
    &__frame2-text2 {
      padding-top: 20% !important;
    }
  }
  .anr-simple__expanded-content {
    .anr-simple__content {
      .anr-simple__sec2-intro {
        margin-bottom: 3px;
      }
      .anr-simple__sec2hovertext {
        display: none;
      }
      margin: 1%;
    }
  }
  .anr-simple__section {
    h2.anr-simple__header-subhead {
      font-size: 63px;
    }
    .anr-simple__content {
      .anr-simple {
        &__sec2plus-headline {
          font-size: 40px !important;
        }
      }
    }
  }
  .hero-block {
    margin: 0px auto 30px auto;
  }
  .mpp__product-grid {
    .product_brief__quantity {
      width: 55px;
    }
  }
}

.customer-service {
  .active-trail {
    .level-1 {
      background-image: none;
    }
  }
}

#cs-container {
  width: 800px;
  margin: 0 auto;
  min-height: 600px;
  #email_unsubscribe {
    margin: 0 0 0 242.5px;
  }
}

@media (min-width: 737px) and (max-width: 1024px) and (orientation: landscape) {
  .anr-simple {
    &__container-fluid {
      .anr-simple {
        &__header-subhead {
          font-size: 85px;
        }
        &__sec2plus-headline {
          margin-top: 0;
          font-size: 40px !important;
        }
      }
    }
  }
}

body.section-advanced-night-repair {
  .advanced-night-repair-page {
    .anr-simple__section--section2--right {
      .anr-simple__sec2plus-headline {
        font-size: 53px;
      }
    }
  }
}

@media (min-width: 768px) {
  .anr-simple__button {
    width: 47px;
    height: 47px;
    line-height: 45px;
    background: white url('/media/boutiques/anr-simple-drupal/anr_simple_cross.png') 0 -45px no-repeat;
    text-indent: -99999px;
    color: black;
    border: 1px solid black;
    margin: auto;
    cursor: pointer;
  }
  .anr-simple__button.closed {
    line-height: 45px;
    background-position: 0 0;
  }
  .anr-simple__button-wrap {
    position: absolute;
    z-index: 10;
    margin-top: -47px;
    height: 47px;
    width: 100%;
    text-align: center;
  }
}

.brand-renutriv {
  .product_brief__buttons {
    .product_brief__button--shop-now {
      line-height: 28px !important;
    }
  }
}

.welcome15-overlay {
  #cboxWrapper {
    height: 335px !important;
    width: 589px !important;
    #cboxContent {
      background: none;
      .welcome_popup {
        left: 0;
        position: absolute;
        top: 0;
        height: 335px;
        width: 589px;
        .popup_offer_heading,
        .popup_offer_body,
        .offer_link,
        .popup_offer_text {
          padding-left: 10px;
        }
        .popup_offer_heading h4,
        .offer_link h4 {
          font-size: 25px;
          padding-top: 8px;
        }
        .popup_offer_body h5 {
          font-size: 16px !important;
          width: 271px;
        }
        .popup_offer_text {
          width: 210px;
        }
      }
    }
  }
}

body.page-products-urun-listesi-re-nutriv-koleksiyonlar-ultimate-diamond {
  background: black;
  color: white;
  .page-wrapper a {
    color: white;
  }
  .page-main .mpp__product-sort-container .mpp__product-sort {
    background-color: black;
    border-color: black;
    color: white;
  }
  .selectBox-arrow {
    border-top-color: white;
  }
  a.product_brief__button--shop-now.button--secondary.button--light {
    color: #c3a36e;
    text-transform: uppercase;
    font-family: 'helvetica-neue';
    margin: 0 auto;
    border-color: #c3a36e;
    line-height: 26px;
    background: black;
  }
  .mpp__product {
    color: white;
  }
  .product_brief__quantity-container.js-quantity {
    a.selectBox.product_brief__quantity.selectbox.selectBox-dropdown {
      color: black;
    }
  }
  h2.mpp__header {
    text-transform: uppercase;
  }
}

#vb_container {
  .extended-mpp {
    .mpp__product {
      .button--secondary,
      .button-color-picker {
        height: 40px;
        font-size: 9px !important ;
        padding: 0 !important;
        line-height: 40px;
      }
      .product_brief__headers .product_brief__shadename.sized {
        padding-left: 26px;
      }
    }
  }
  .product_brief__buttons-container {
    .pre-order_msg.pre-order_active_msg.hidden {
      display: none !important;
    }
    .pre-order_status_display {
      height: auto;
    }
  }
}

.appt-book {
  .appt-book-page-header {
    padding-top: 20px;
  }
  .service-select {
    .section-header {
      padding: 5px 0;
    }
    .service {
      .service-details {
        bottom: auto;
        margin-top: 5px;
        h3.service-title {
          line-height: 1.1em;
        }
      }
    }
  }
  .book-appt-container {
    input {
      color: $color-black;
    }
    .mobile-phone-fieldset {
      float: right;
      input.appt-book-mobile-prefix {
        width: 20%;
        border-right: none;
      }
      input.appt-book-mobile {
        width: 80%;
        float: right;
        margin-bottom: 15px;
      }
    }
    .appointment_privacy_legal_text,
    .registration__privacy-policy-list,
    .regristration_loyalty-list {
      clear: both;
      padding-top: 20px;
    }
  }
  .sign-in-form-container {
    .login__email,
    .login__password {
      color: $color-black;
    }
    .social-login {
      display: none;
    }
  }
  .appointment-select {
    .appointments-container {
      .confirm-container {
        .appt-book-book-submit {
          height: 54px;
        }
      }
    }
  }
  .confirmation-container,
  .appointment-details {
    .artist {
      display: none;
    }
  }
}

.appt-book-overlay.appt-book-services-overlay {
  .overlay-content-container {
    height: auto;
    padding: 30px 35px;
  }
}

@media #{$device-ipad_only} {
  .appt-book-datepicker-overlay {
    @media #{$portrait} {
      .pika-single {
        .pika-lendar {
          width: 280px;
          .pika-label {
            font-size: 23px;
            letter-spacing: 0.025em;
          }
          .pika-button {
            text-align: center;
          }
        }
      }
    }
    @media #{$landscape} {
      .overlay-content-container {
        padding: 60px 20px;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          input.login__email {
            width: 40%;
          }
        }
      }
    }
  }
}

@media #{$xlarge-up} {
  .service-select {
    .services-container {
      .service {
        .service-details {
          position: relative;
          margin-left: 5%;
          left: 0;
          height: 236px;
          .selection-bar {
            position: absolute;
          }
        }
      }
    }
  }
}

.device-pc {
  .headline--medium,
  .headline--large {
    font-family: OptimaNovaLTPro-Light;
  }
}

.mpp__header,
.mpp__bestfor--headline {
  font-family: $optimanova;
}

.signin-overlay-wrapper {
  #cboxContent {
    height: 435px;
    .social-login__container {
      float: left;
      margin-left: -15%;
      margin-top: -44%;
      width: 55%;
      .inner {
        display: none;
      }
      .social-login__divider:before {
        background: none;
      }
    }
    .sign-in-component__form {
      float: right;
      border-left: 1px solid $color-darker-gray;
      padding-left: 11%;
      height: 419px;
      .sign-in-component__registration-options {
        .text--form-help label {
          margin: 2px 0px 2px 0px;
        }
      }
    }
  }
}

.legal-presales-info-body {
  .LPMcontainer {
    display: none !important;
  }
}

.co_updates_enabled {
  .adpl {
    input[type='text'] {
      &.error {
        border-color: $color-red;
      }
    }
  }
}
