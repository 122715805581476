.brand-renutriv .product_brief__buttons--non-shaded .product_brief__button--shop-now {
  line-height: 33px;
}

@media (max-width: 425px) {
  .product-brief__button-quickshop {
    line-height: 43px !important;
  }
  .product-brief__button-quickshop--close {
    text-indent: 0;
    background-image: none;
  }
}

.device-mobile {
  .account-favorites__list {
    .button--dark-secondary {
      line-height: normal;
    }
  }
}

.offer-code-panel {
  input[type='submit'] {
    width: 100% !important;
    letter-spacing: 0.07em;
    padding: 0px;
  }
}

#address_book {
  .address-book-info {
    .address_display {
      .controls {
        .billing-address {
          border: none;
        }
      }
    }
  }
}

.mpp-compare {
  .compare_add_to_bag {
    width: 100%;
    left: 0;
    a {
      border: 1px solid #808080;
      color: #ffffff;
    }
  }
}

.spp {
  .spp-product__anchor {
    .spp-product__anchor-tab {
      top: 8%;
    }
  }
}

.mpp-compare__bag {
  .mpp-compare__bag-container {
    float: none;
    width: auto;
    min-height: 180px !important;
    .compare_add_to_bag {
      float: none;
      position: absolute !important;
    }
    .mpp-compare__swatch-list-container {
      .mpp-compare__swatch-list {
        min-height: initial;
      }
    }
  }
}
